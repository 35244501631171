/* eslint-disable no-undef */
const injectSplashScreen = () => {
  if (typeof renderSplashScreen !== "undefined" && window.screen.width <= 540) {
    renderSplashScreen({
      app: "truckSearch",
    });
  }
};
/* eslint-enable */

export default injectSplashScreen;
